
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { toLocalDate } from '@/utils/dates';
import { sleep, dateDiff } from '@/utils/helpers';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { DateTime } from 'luxon';
// import workTicket from '@/router/routes/workTicket';
import tasqsListModule from '../../store/modules/tasqsListModule';

@Component
export default class WorkTicketCalenderView extends mixins(GenericMixin) {
  reloadGanttChartKey = 0;

  reloadGanttChartComponentsKey = 0;

  dates: any[] = [];

  chartStartDatetime;

  currentTooltipID = '';

  chartEndDateTime;

  isLoadingLeftArrow = false;

  isLoadingRightArrow = false;

  loadingGanttChart = false;

  currentDate = '';

  viewTotalDays = 7;

  calendarColumnWidth = 150;

  calendarRowStackedHeight = 40;

  calendarRowHeight = 80;

  tooltipX = 0;

  tooltipY = 0;

  get statusLegends() {
    return ['Open', 'In Progress', 'Blocked', 'Complete'].sort();
  }

  // Methods goes below

  getTicketColor(status) {
    if (status === 'In Progress') {
      return '#2E6596';
    }

    if (status === 'Blocked') {
      return '#5E1D1D';
    }

    if (status === 'Complete') {
      return '#216635';
    }

    return '#66561E';
  }

  setActiveWorkTicket(ticket) {
    tasqsListModule.setActiveWorkTicket(ticket);
  }

  getRowColorLeft() {
    return 'background: rgba(0,0,0,0.0);';
  }

  getRowTitleWidth() {
    return 'padding-left: 36px; width: 270px; ';
  }

  get currentDay(): any {
    return new Date().toLocaleDateString();
  }

  get filteredWorkTickets() {
    return tasqsListModule.filteredWorkTickets;
  }

  get filteredAssets() {
    let updatedWorkTickets = this.filteredWorkTickets.filter((ticket) => ticket.ticketStartDate);

    const startDay = this.dates[0] && this.dates[0].day ? this.dates[0].day : new Date();

const endDay = this.dates[this.dates.length - 1] && this.dates[this.dates.length - 1].day
 ? this.dates[this.dates.length - 1].day : new Date();

    updatedWorkTickets = updatedWorkTickets.filter((asset) => {
  const ticketStartDate: Date = toLocalDate(asset.ticketStartDate);

  const ticketDueDate: Date = asset.ticketDueDate ? toLocalDate(asset.ticketDueDate) : ticketStartDate;
    if (
    (ticketStartDate.getTime() <= startDay.getTime()
      && ticketDueDate.getTime() >= endDay.getTime())
    || (ticketStartDate.getTime() >= startDay.getTime()
      && ticketStartDate.getTime() <= endDay.getTime())
    || (ticketDueDate.getTime() <= endDay.getTime()
      && ticketDueDate.getTime() >= startDay.getTime())) {
      return true;
    }
  return false;
 });

    updatedWorkTickets = updatedWorkTickets.reduce((r, a) => {
      if (!a.wellName) {
        // eslint-disable-next-line no-param-reassign
        a.wellName = 'None';
      }
      // eslint-disable-next-line no-param-reassign
      r[a.wellName] = r[a.wellName] || [];
      r[a.wellName].push(a);
      return r;
    }, Object.create(null));
    return Object.keys(updatedWorkTickets)
      .map((key) => ({
        assetName: key,
        level: 'pad',
        sub_rows: updatedWorkTickets[key],
      }))
      .sort((a, b) => a.assetName.localeCompare(b.assetName));
  }

  getDayOfWeekStringFromDate(datetime) {
    return this.getDayOfWeek(datetime.day)?.toUpperCase();
  }

  getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return Number.isNaN(dayOfWeek)
      ? null
      : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
  }

  calculateTotalRowHeight(rowCount) {
    return (
      (this.calendarRowHeight - this.calendarRowStackedHeight) * (rowCount - 1)
      + this.calendarRowHeight
    );
  }

  async created() {
    const startDateTime = new Date();
    startDateTime.setHours(0, 0, 0, 0);
    this.chartStartDatetime = startDateTime;
    tasqsListModule.setFilteredWorkTickets(tasqsListModule.workTickets);
    // await this.prepareView();
  }

  async prepareView() {
    this.addDataToGanttChart();
  }

  chartStartDatetimeWithOffset() {
    const modifyDate = new Date(this.chartStartDatetime.getTime());
    return new Date(modifyDate.setHours(modifyDate.getHours() - 48));
  }

  updateTooltipCursor(e) {
    this.tooltipX = e.x;
    this.tooltipY = e.y;
  }

  async addDataToGanttChart() {
    const startDateTime = new Date(this.chartStartDatetimeWithOffset().getTime());
    const startDateTimeTemp = new Date(this.chartStartDatetimeWithOffset().getTime());
    const endDateTime = new Date(
      startDateTimeTemp.setHours(startDateTimeTemp.getHours() + this.viewTotalDays * 24),
    );
    endDateTime.setHours(0, 0, 0, 0);
    this.chartEndDateTime = new Date(endDateTime.getTime());

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    if (
      monthNames[this.chartStartDatetimeWithOffset().getMonth()]
      !== monthNames[endDateTime.getMonth()]
    ) {
      this.currentDate = `${monthNames[this.chartStartDatetimeWithOffset().getMonth()]} — ${
        monthNames[endDateTime.getMonth()]
      } ${this.chartStartDatetimeWithOffset().getFullYear()}`;
    } else {
      this.currentDate = `${
        monthNames[this.chartStartDatetimeWithOffset().getMonth()]
      } ${this.chartStartDatetimeWithOffset().getFullYear()}`;
    }

    this.dates = [];

    // Show chart 30 days
    // eslint-disable-next-line no-plusplus
    for (let days = 0; days < this.viewTotalDays; days++) {
      let dayDateTime = new Date(startDateTime);
      dayDateTime = new Date(dayDateTime.setDate(startDateTime.getDate() + days));
      const hoursArray: Date[] = [];
      const dayObject = {
        day_id: dayDateTime.toString(),
        day_short: dayDateTime.getDate(),
        day: dayDateTime,
        dayDisplay: new Date(dayDateTime).toLocaleDateString().slice(0, -5),
        hours: hoursArray,
        localDate: new Date(dayDateTime).toLocaleDateString(),
      };
      // eslint-disable-next-line no-plusplus
      for (let hours = 0; hours < 24; hours++) {
        let hourDateTime = new Date(dayDateTime);
        hourDateTime = new Date(hourDateTime.setHours(dayDateTime.getHours() + hours));
        dayObject.hours.push(hourDateTime);
      }
      this.dates.push(dayObject);
    }
  }

  async weekArrowSelected(direction) {
    // const tempStartDate = new Date(this.chartStartDatetime.getTime());
    // let startDateTime = new Date(tempStartDate.setHours(tempStartDate.getHours() + (7 * 24)));
    let startDateTime = new Date(
      new Date(DateTime.fromJSDate(this.chartStartDatetime).plus({ days: 7 }).toISO()).setHours(
        0,
        0,
        0,
      ),
    );
    if (direction.toLowerCase() === 'left') {
      this.isLoadingLeftArrow = true;
      // startDateTime = new Date(tempStartDate.setHours(tempStartDate.getHours() - (7 * 24)));
      // console.log(startDateTime);
      startDateTime = new Date(
        new Date(DateTime.fromJSDate(this.chartStartDatetime).minus({ days: 7 }).toISO()).setHours(
          0,
          0,
          0,
        ),
      );
      // console.log(startDateTime);
    } else {
      this.isLoadingRightArrow = true;
    }

    startDateTime.setHours(0, 0, 0, 0);
    this.chartStartDatetime = startDateTime;
    this.reloadPage();
    await sleep(500);
    this.isLoadingRightArrow = false;
    this.isLoadingLeftArrow = false;
  }

  getScheduleRowHeight(rowIndex, totalRows) {
    return rowIndex === totalRows - 1 ? this.calendarRowHeight : this.calendarRowStackedHeight;
  }

  didSelectToday() {
    const startDateTime = new Date(new Date().setHours(new Date().getHours()));
    startDateTime.setHours(0, 0, 0, 0);

    this.chartStartDatetime = startDateTime;
    this.reloadPage();
  }

  async reloadPage() {
    this.loadingGanttChart = true;
    this.dates = [];

    await this.addDataToGanttChart();
    await sleep(100);
    this.loadingGanttChart = false;
  }

  getComponentWidth(startDate, endDate) {
    if (startDate.indexOf('undefined') !== -1 || endDate.indexOf('undefined') !== -1) {
      return '0';
    }
    const widthDaysDiff = dateDiff(new Date(startDate), new Date(endDate)) + 1;
    return `${(widthDaysDiff * this.calendarColumnWidth).toString()}px`;
  }

  getComponentLeftPosition(startDate) {
    if (startDate.indexOf('undefined') !== -1) {
      return '0';
    }

    const date: Date = toLocalDate(startDate);
    const diffDays = Math.floor(
        // @ts-ignore
      (date - this.chartStartDatetimeWithOffset()) / (1000 * 60 * 60 * 24),
    );
    return `${(diffDays * this.calendarColumnWidth).toString()}px`;
  }
}
